<template>
  <div class="register_box main">
    <div class="step bg">
      <div class="container">
        <div class="t1">Create an Account</div>
        <div class="text">
          Fill out the information below to create an account for entry
          submission.
        </div>
      </div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-loading="loading"
    >
      <div class="step form_list">
        <div class="container">
          <div class="t2">Login Details</div>
          <el-form-item label="Email" prop="member_email">
            <el-input v-model="ruleForm.member_email"></el-input>
            <span>(This will be your user ID)</span>
          </el-form-item>
          <el-form-item label="Confirm Email" prop="member_email2">
            <el-input v-model="ruleForm.member_email2"></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              show-password
            ></el-input>
            <span
              >(Please remember the password and record it in case it is
              forgotten or lost.)</span
            >
          </el-form-item>
          <el-form-item label="Confirm Password" prop="password2">
            <el-input v-model="ruleForm.password2" show-password></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="step bg step2 form_list">
        <div class="container">
          <div class="left">
            <div class="t2">Personal Details</div>
            <el-form-item label="Appellation" prop="member_sex">
              <el-select
                v-model="ruleForm.member_sex"
                placeholder="Appellation"
              >
                <el-option value="0" label="Mr."></el-option>
                <el-option value="1" label="Ms."></el-option>
                <el-option value="2" label="Miss"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Name" prop="member_name">
              <el-input v-model="ruleForm.member_name"></el-input>
            </el-form-item>
            <el-form-item label="Job Title" prop="member_title">
              <el-input v-model="ruleForm.member_title"></el-input>
            </el-form-item>
            <el-form-item label="Telephone" prop="member_phone">
              <el-input v-model="ruleForm.member_phone"></el-input>
            </el-form-item>
            <el-form-item label="Cellphone" prop="member_mobile">
              <el-input v-model="ruleForm.member_mobile"></el-input>
            </el-form-item>
            <el-form-item label="Alternative Email" prop="member_bakemail">
              <el-input v-model="ruleForm.member_bakemail"></el-input>
            </el-form-item>
            <el-form-item label="Business Card" prop="other_info_business_card">
              <el-upload
                class="upload_btn"
                :before-upload="
                  file => {
                    return beforeAvatarUpload(file, 2);
                  }
                "
                :on-success="
                  (response, file, fileList) => {
                    return handleAvatarSuccess(response, file, fileList, 2);
                  }
                "
                action="https://oss.eciawards.org.cn/"
                :data="upload_data"
                :file-list="file_list"
                :limit="1"
              >
                <el-button size="small" type="primary">UPLOAD</el-button>
              </el-upload>
            </el-form-item>
          </div>
          <div class="right">
            <div class="t2">Alternative contact</div>
            <el-form-item label="Appellation" prop="other_info_sex">
              <el-select
                v-model="ruleForm.other_info_sex"
                placeholder="Appellation"
              >
                <el-option value="0" label="Mr."></el-option>
                <el-option value="1" label="Ms."></el-option>
                <el-option value="2" label="Miss"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Name" prop="other_info_name">
              <el-input v-model="ruleForm.other_info_name"></el-input>
            </el-form-item>
            <el-form-item label="Title" prop="other_info_title">
              <el-input v-model="ruleForm.other_info_title"></el-input>
            </el-form-item>
            <el-form-item label="Telephone" prop="other_info_phone">
              <el-input v-model="ruleForm.other_info_phone"></el-input>
            </el-form-item>
            <el-form-item label="Cellphone" prop="other_info_mobile">
              <el-input v-model="ruleForm.other_info_mobile"></el-input>
            </el-form-item>
            <el-form-item label="E-mail" prop="other_info_bakemail">
              <el-input v-model="ruleForm.other_info_bakemail"></el-input>
            </el-form-item>
            <div class="help">
              Please fill in another person in case the first contact person is
              unable to respond due to certain reasons (resignation or
              department change, etc.).
            </div>
          </div>
        </div>
      </div>
      <div class="step form_list">
        <div class="container">
          <div class="t2">Company Details</div>
          <el-form-item label="Company Name" prop="member_company">
            <el-input v-model="ruleForm.member_company"></el-input>
          </el-form-item>
          <el-form-item label="Industry" prop="other_info_industry"
            ><el-select
              v-model="ruleForm.other_info_industry"
              placeholder="Industry"
            >
              <!-- <el-option value="0" :label="''"></el-option> -->
              <el-option
                value="1"
                label="Creative/Advertising Agency"
              ></el-option>
              <el-option
                value="2"
                label="Digital Marketing Company"
              ></el-option>
              <el-option value="3" label="Media Owner"></el-option>
              <el-option value="4" label="Media Agency"></el-option>
              <el-option value="5" label="PR Company"></el-option>
              <el-option value="6" label="Brand Owner"></el-option>
              <el-option
                value="7"
                label="Strategy Consultation/Research Agency"
              ></el-option>
              <el-option value="8" label="E-Commerce Company"></el-option>
              <el-option value="9" label="Others"></el-option> </el-select
          ></el-form-item>
          <el-form-item label="Address" prop="member_address">
            <el-input v-model="ruleForm.member_address"></el-input>
          </el-form-item>
          <el-form-item label="City" prop="member_city">
            <el-input v-model="ruleForm.member_city"></el-input>
          </el-form-item>
          <el-form-item label="Country/District" prop="member_country">
            <el-input v-model="ruleForm.member_country"></el-input>
          </el-form-item>
          <el-form-item label="Zip code" prop="member_code">
            <el-input v-model="ruleForm.member_code"></el-input>
          </el-form-item>
          <el-form-item label="Fax" prop="member_fax">
            <el-input v-model="ruleForm.member_fax"></el-input>
          </el-form-item>
          <el-form-item label="Website" prop="member_web">
            <el-input v-model="ruleForm.member_web"></el-input>
          </el-form-item>
          <el-form-item
            label="Company Profile"
            class="textarea"
            prop="company_intro"
          >
            <el-input
              type="textarea"
              v-model="ruleForm.company_intro"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`Detailed Company\nIntroduction`"
            prop="other_info_company_intro"
            class="twoline"
          >
            <!-- 1号上传" -->
            <el-upload
              class="upload_btn"
              :before-upload="
                file => {
                  return beforeAvatarUpload(file, 1);
                }
              "
              :on-success="
                (response, file, fileList) => {
                  return handleAvatarSuccess(response, file, fileList, 1);
                }
              "
              action="https://oss.eciawards.org.cn/"
              :data="upload_data"
              :file-list="file_list"
              :limit="1"
            >
              <el-button size="small" type="primary">UPLOAD</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="Tel" prop="company_tel">
            <el-input v-model="ruleForm.company_tel"></el-input>
          </el-form-item>
          <el-form-item
            :label="`Contact Person\n(superior to the VP)`"
            class="twoline"
          >
            <el-input v-model="ruleForm.company_contacts"></el-input>
          </el-form-item>
          <el-form-item label="Position">
            <el-input v-model="ruleForm.contacts_position"></el-input>
          </el-form-item>
          <el-form-item label="Contact Detail">
            <el-input v-model="ruleForm.contacts_way"></el-input>
          </el-form-item>
          <el-form-item label="Contact Person of">
            <el-input v-model="ruleForm.brand_contacts"></el-input>
          </el-form-item>
          <el-form-item label="Position">
            <el-input v-model="ruleForm.brand_position"></el-input>
          </el-form-item>
          <el-form-item label="Contact Details">
            <el-input v-model="ruleForm.brand_contacts_way"></el-input>
          </el-form-item>
          <el-form-item label="Major Client">
            <el-input v-model="ruleForm.customer"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="step bg">
        <div class="container">
          <div class="t2">
            Where did you get the information about the competition？*
          </div>
          <div class="other_check">
            <el-checkbox
              label="Enterprise"
              name="type"
              v-model="check.enterprise_name"
            ></el-checkbox>
            <el-input
              placeholder="Enter enterprise name here."
              v-model="ruleForm.enterprise_name"
            ></el-input>
          </div>
          <div class="other_check">
            <el-checkbox
              label="Trade guild"
              name="type"
              v-model="check.trade_name"
            ></el-checkbox>
            <el-input
              placeholder="Enter trade guild here."
              v-model="ruleForm.trade_name"
            ></el-input>
          </div>
          <div class="other_check">
            <el-checkbox
              label="Other media"
              name="type"
              v-model="check.media_name"
            ></el-checkbox>
            <el-input
              placeholder="Enter the media name here."
              v-model="ruleForm.media_name"
            ></el-input>
          </div>
          <div class="other_check">
            <el-checkbox
              label="Recommended by a judge"
              name="type"
              v-model="check.ex_name"
            ></el-checkbox>
            <el-input
              placeholder="Enter the judge name here."
              v-model="ruleForm.ex_name"
            ></el-input>
          </div>
          <el-form-item label="Social medias of ECI Awards" class="checkgroup">
            <el-checkbox-group v-model="eci">
              <el-checkbox label="1">Official website</el-checkbox>
              <el-checkbox label="2">Wechat</el-checkbox>
              <el-checkbox label="3">Facebook</el-checkbox>
              <el-checkbox label="4">Magazine</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="ECI Awards Committee" class="checkgroup">
            <el-checkbox-group v-model="zu">
              <el-checkbox label="1">Invitation letter</el-checkbox>
              <el-checkbox label="2">Newsletter</el-checkbox>
              <el-checkbox label="3">Invitation call</el-checkbox>
              <el-checkbox label="4">Events</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="other_check">
            <el-checkbox label="Other" name="type"></el-checkbox>
            <el-input
              placeholder="Enter text here."
              v-model="ruleForm.other_name"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="step">
        <div class="container">
          <div class="button submit" @click="submitForm('ruleForm')">
            Submit
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { get_aliyun_sign, do_register } from '@/api/reg.js';

export default {
  name: 'Register',
  components: {},
  data() {
    let validateEmail = (rule, value, callback) => {
      if (
        !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
          value
        )
      ) {
        callback(new Error('The email address is incorrect.'));
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value != this.ruleForm.password) {
        callback(
          new Error('Password and Confirm Password fields do not match.')
        );
      } else {
        callback();
      }
    };
    let validateEmail2 = (rule, value, callback) => {
      if (value != this.ruleForm.member_email) {
        callback(new Error('Email and Confirm Email fields do not match.'));
      } else {
        callback();
      }
    };
    let validateAR = (rule, value, callback) => {
      if (value == this.ruleForm.member_email) {
        callback(
          new Error(
            'The alternative email cannot be same as the registered email.'
          )
        );
      } else {
        callback();
      }
    };

    return {
      loading: false,
      check: {
        enterprise_name: false,
        trade_name: false,
        media_name: false,
        ex_name: false,
      }, //勾选
      upload_url: '',
      file_list: [],
      upload_data: {
        name: '',
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: 0,
        signature: '',
      },
      eci: [], //多选1
      zu: [], //多选2
      other_info: {}, //其他信息
      ruleForm: {},
      rules: {
        member_email: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
        ],
        member_email2: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
          { validator: validateEmail2, trigger: 'blur' },
        ],
        password: [
          {
            required: true,
            message: 'Password must be between 6 and 10 characters in length.',
            trigger: 'blur',
            min: 6,
            max: 10,
          },
          // Password must be between 6 and 10 characters in length.
        ],
        password2: [
          {
            required: true,
            message: 'Password must be between 6 and 10 characters in length.',
            trigger: 'blur',
            min: 6,
            max: 10,
          },
          { validator: validatePass2, trigger: 'blur' },
        ],
        member_sex: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_title: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_phone: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_mobile: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_bakemail: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
          { validator: validateAR, trigger: 'blur' },
        ],
        other_info_sex: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_title: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_phone: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_mobile: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_bakemail: [
          { required: true, message: 'Please enter', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
        ],
        member_company: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_industry: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_address: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_city: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        member_country: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        company_intro: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        other_info_company_intro: [
          { required: true, message: 'Please Upload', trigger: 'blur' },
        ],
        company_tel: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},

  methods: {
    //s上传前
    beforeAvatarUpload(file, id) {
      //Please upload files in pdf, ppt, pptx, doc, docx, txt formats.
      let arr = ['pdf', 'ppt', 'pptx', 'doc', 'docx', 'txt'];
      let wordarr = file.name.split('.');

      let key = wordarr.pop();
      console.log(key);
      if (arr.indexOf(key) == -1 && id == 1) {
        //格式不对
        console.log('格式不对');
        this.$message.warning(
          'Please upload files in pdf, ppt, pptx, doc, docx, txt formats.'
        );
        return false;
      }
      //先判断是否填写了email
      if (!this.ruleForm.member_email) {
        this.$message.warning('Please input your email.');
        return false;
      } else {
        return new Promise((resolve, reject) => {
          this.$nextTick(() => {
            get_aliyun_sign({
              dir: 'USERFILE/' + this.ruleForm.member_email + '/',
            }).then(res => {
              this.upload_data = {
                name: file.name,
                key: res.dir + file.name,
                policy: res.policy,
                OSSAccessKeyId: res.accessid,
                success_action_status: 200,
                signature: res.signature,
              };
              this.upload_url = res.host;
              console.log(this.upload_data);
              resolve(true);
            });
          });
        });
      }
    },
    //上传成功后
    handleAvatarSuccess(response, file, fileList, id) {
      //上传成功
      console.log('上传成功');

      let url = encodeURIComponent(
        this.upload_url + 'USERFILE/' + this.ruleForm.member_email + file.name
      );
      console.log(url);
      if (id == 1) {
        this.ruleForm.other_info_company_intro = url;
      } else {
        this.ruleForm.other_info_business_card = url;
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        console.log(valid);
        if (valid) {
          console.log(this.ruleForm);
          //判断是否勾选了check
          let isCheck = false;
          for (let i in this.check) {
            if (this.check[i] == true) {
              console.log(i);
              console.log(this.ruleForm[i]);
              if (this.ruleForm[i]) {
                isCheck = true;
              }
            }
          }
          if (isCheck) {
            //梳理form数据，提交数据
            this.ruleForm.other_info = {
              business_card: this.ruleForm.other_info_business_card || '',
              sex: this.ruleForm.other_info_sex || '',
              name: this.ruleForm.other_info_name || '',
              title: this.ruleForm.other_info_title || '',
              phone: this.ruleForm.other_info_phone || '',
              mobile: this.ruleForm.other_info_mobile || '',
              bakemail: this.ruleForm.other_info_bakemail || '',
              industry: this.ruleForm.other_info_industry || '',
              company_intro: this.ruleForm.other_info_company_intro || '',
            };
            this.ruleForm.eci = this.eci;
            this.ruleForm.zu = this.zu;

            //去提交
            this.saveData(this.ruleForm);
          } else {
            this.$message({
              type: 'warning',
              message: 'Please enter your information source.',
            });
          }
        } else {
          console.log('error submit!!');
          this.$message({
            type: 'warning',
            message: 'Please complete',
          });
          return false;
        }
      });
    },
    saveData(data) {
      this.loading = true;
      do_register(data).then(res => {
        console.log(res);
        this.loading = false;
        if (res.errcode == 2) {
          //注册成功跳转到登录
          this.$message({
            type: 'success',
            message:
              'Successfully Registered, our staff will activite it manually,  PLEASE WAIT 24 hours then you can log in, please forgive us for the inconvinience.',
          });
          this.$router.push({
            name: 'Login',
          });
        } else {
          this.$message({
            type: 'warning',
            message: res.errmsg,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/form.scss';
.register_box {
  font-size: 14px;
  .step {
    padding: 50px 0;
  }
  .bg {
    background-color: #f4f4f4;
  }
  .t1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .t2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .step2 {
    .container {
      display: flex;
    }

    .left {
      width: 42%;
    }
    .right {
      width: 42%;

      .help {
        color: #d0d0d0;
      }
    }
  }
  .submit {
    margin: 0 0;
  }
}
@media (max-width: 1000px) {
  .register_box {
    .step2 {
      .container {
        display: block;
      }
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
}
</style>
